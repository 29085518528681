<template>
    <div class="app-container">
        <div>
            <div class="content-top">
                <p class="title">客户信息</p>
                <div class="text" v-for="(item, index) in customerData" :key="index">
                    <span class="text-item" v-for="(j, k) in item.row" :key="k">
                        <span>{{ j.title }}：</span><span> {{ j.text || '-' }}</span>
                    </span>
                </div>
                <!-- <div class="btm">
                    <div class="customerbtm bgcolor" @click="goDetails('0')">写跟进</div>
                    <div class="customerbtm" @click="goDetails('1')">编辑信息</div>
                    <div class="customerbtm" @click="goDetails('2')">转移客户</div>
                    <div class="customerbtm" @click="goDetails('3')">放入公海</div>
                    <div class="customerbtm boxwidth" @click="goDetails('4')">更改房源状态</div>
                </div> -->
            </div>
            <div class="content-bottom">
                <div class="box"></div>
                <div class="bottom-title">
                    <span v-for="(item, index) in serveList" :key="index"
                        :class="statusIcon === item.status ? 'active-text' : 'title-text'"
                        @click="changeStyle(item.status)"> {{ item.title }} </span>
                </div>
                <div v-if="isShowData">
                    <div v-if="statusIcon == 1">
                        <div class="bottom-content" v-for="(item, index) in recordsVOS" :key="index">
                            <div class="time">
                                <img src="../../../../../../assets/images/customerRM/round.png" alt="">
                                <div class="time-text">{{ item.creationDate }}</div>
                            </div>
                            <div class="serve">
                                <div class="serve-person">操作人：{{ item.createdBy }}</div>
                                <div class="box-width">
                                    <div class="serve-content">
                                        <div class="SC-text">
                                            <p class="SC-title">跟进内容:</p>
                                            <p class="SC-content">{{ item.description }}</p>
                                        </div>
                                        <div class="SC-text">
                                            <p class="SC-title">来源:</p>
                                            <p class="SC-content">{{ item.resource }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="statusIcon == 2">
                        <div class="bottom-content" v-for="(item, index) in recordsVOS" :key="index">
                            <div class="time">
                                <img src="../../../../../../assets/images/customerRM/round.png" alt="">
                                <div class="time-text">{{ item.createTime }}</div>
                            </div>
                            <div class="serve">
                                <div class="serve-person">操作人：{{ item.createdBy }}</div>
                                <div class="box-width">
                                    <div class="serve-content">
                                        <div class="SC-text">
                                            <p class="SC-title">跟进链接:</p>
                                            <p class="SC-content">
                                                <a :href="url + item.pdfFile" download style="color: #4374FA;">{{ url + item.pdfFile }}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="present" v-else>
                    暂无数据
                </div>
            </div>
        </div>
        <!-- <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px">
            <template slot="title">
                <span>{{ customerDataDialog.dialogTitle }}</span>
            </template>
<customerViewDialog v-if="dialogVisible" @close="handleClose" :id="customerDataDialog.id"
    :dialogStatus="customerDataDialog.dialogStatus" :dialogTitle="customerDataDialog.dialogTitle">
</customerViewDialog>
</el-dialog> -->
    </div>
</template>

<script>
// import customerViewDialog from "./customerViewDialog.vue"
import {
    getCustomerDatail,
    transferCustomers
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { envInfo } from "@/constants/envInfo";
export default {
    components: {
        // customerViewDialog
    },
    data() {
        return {
            statusIcon: 1,
            isShowData: false,
            url: envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fi=",
            serveList: [
                {
                    title: '跟进记录',
                    status: 1
                },
                {
                    title: '报价单',
                    status: 2
                },
            ],
            dialogVisible: false,
            customerDataDialog: {
                dialogStatus: 'add',
                dialogTitle: "写跟进",
            },
            recordsVOS: [],
            customerData: [
                {
                    row: [
                        {
                            title: '客户企业名称',
                            text: '',
                        },
                        {
                            title: '客户编号',
                            text: '',
                        },
                        {
                            title: '租户类型',
                            text: '',
                        },
                        {
                            title: '企业类型',
                            text: '',
                        },
                        {
                            title: '客户来源',
                            text: '',
                        },
                    ]
                },
                {
                    row: [
                        {
                            title: '联系人',
                            text: '',
                        },
                        {
                            title: '联系电话',
                            text: '',
                        },
                        {
                            title: '邮箱',
                            text: '',
                        },
                        {
                            title: '地址',
                            text: '',
                        },
                    ]
                },
                {
                    row: [
                        {
                            title: '销售人员',
                            text: '',
                        },
                        {
                            title: '职务',
                            text: '',
                        },
                        {
                            title: '电话',
                            text: '',
                        },
                        {
                            title: '微信号',
                            text: '',
                        },
                    ]
                },
                {
                    row: [
                        {
                            title: '项目',
                            text: '',
                        },
                        {
                            title: '楼栋',
                            text: '',
                        },
                        {
                            title: '房间号',
                            text: '',
                        },
                        {
                            title: '房源状态',
                            text: '',
                        },
                    ]
                },
            ]
        }
    },
    created() {
        this.getDetailList();
    },
    methods: {
        getDetailList() {
            let id = this.$route.query.id
            getCustomerDatail({ id }).then(res => {
                console.log(res, '详情');
                if (res) {
                    this.customerData[0].row[0].text = res.data.enterpriseName
                    this.customerData[0].row[1].text = res.data.customerCode
                    this.customerData[0].row[2].text = res.data.tenantType
                    this.customerData[0].row[3].text = res.data.enterpriseType
                    this.customerData[0].row[4].text = res.data.source
                    this.customerData[1].row[0].text = res.data.contacts
                    this.customerData[1].row[1].text = res.data.contactNumber
                    this.customerData[1].row[2].text = res.data.email
                    this.customerData[1].row[3].text = res.data.address
                    this.customerData[2].row[0].text = res.data.employeeName
                    this.customerData[2].row[1].text = res.data.employeeRole
                    this.customerData[2].row[2].text = res.data.employeePhone
                    this.customerData[2].row[3].text = res.data.employeeWxChat
                    this.customerData[3].row[0].text = res.data.storeName
                    this.customerData[3].row[1].text = res.data.buildingName
                    this.customerData[3].row[2].text = res.data.roomName
                    const statusMap = {
                        1: '已租',
                        2: '在谈',
                        3: '预定',
                        4: '取消',
                        5: '其他'
                    };
                    res.data.roomStatus = statusMap[res.data.roomStatus];
                    this.customerData[3].row[3].text = res.data.roomStatus

                    if (this.statusIcon == 1) {
                        if (res.data.followRecordsVOS != null) {
                            this.recordsVOS = res.data.followRecordsVOS
                        }
                    } else if (this.statusIcon == 2) {
                        if (res.data.quotationVOS = null) {
                            this.recordsVOS = res.data.quotationVOS
                        }
                    }

                    if (this.recordsVOS.length !== 0) {
                        this.isShowData = true
                    } else {
                        this.isShowData = false
                    }
                }
            })
        },
        changeStyle(state) {
            console.log(state);
            this.statusIcon = state
            this.isShowData = false
            this.getDetailList();
        },
        handleClose(freshFlag) {
            this.dialogVisible = false
            freshFlag && this.getDetailList();
        },
        goDetails(type) {
            if (type == '0') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "写跟进";
            } else if (type == '1') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "edit";
                this.customerDataDialog.dialogTitle = "编辑信息";
            } else if (type == '2') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "转移客户";
            } else if (type == '3') {
                this.$confirm(
                    '确定放入公海吗？',
                    this.$t("commons.warning"),
                    {
                        confirmButtonText: this.$t("commons.confirm"),
                        cancelButtonText: this.$t("commons.cancel"),
                        type: "warning",
                    }
                ).then(() => {
                    transferCustomers({
                        id: this.$route.query.id,
                        status: 0, //放入公海：0，分配：1
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.$router.go(-1)
                        } else if (res.code == 500) {
                            this.$message({
                                type: res.msg,
                                message: '操作成功!'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                })
            } else if (type == '4') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "更改房源状态";
            }
        }
    }
}

</script>

<style lang="less" scoped>
.present {
    margin-top: 144px;
    margin-bottom: 140px;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #33333360;
}

.box {
    height: 24px;
}

.app-container {
    background-color: #f1f6fd;
}

.content-top {
    height: 276px;
    background-color: #fff;
    margin-bottom: 16px;
    padding-left: 32px;

    .title {
        font-family: Noto Sans SC, Noto Sans SC;
        padding-top: 32px;
        font-size: 16px;
        color: #2F3941;
        padding-bottom: 8px;
        font-weight: bold;
    }

    .text {
        padding-top: 16px;

        .text-item {
            width: 20%;

            :first-child {
                display: inline-block;
                width: 100px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #999999;
                font-size: 12px;
            }

            :last-child {
                display: inline-block;
                width: 140px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #000000;
                font-size: 12px;
                margin-right: 48px;
            }
        }
    }

    .btm {
        display: flex;
        margin-top: 24px;

        .customerbtm {
            width: 88px;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            color: #1A4CEC;
            border: 1px solid #4374FA;
            text-align: center;
            border-radius: 4px 4px 4px 4px;
            background: #FFFFFF;
            cursor: pointer;
            margin-right: 24px;
            font-weight: bold;
        }

        .bgcolor {
            background: #1A4CEC !important;
            color: #fff !important;
            width: 74px !important;
        }

        .boxwidth {
            width: 116px !important;
        }
    }
}

.content-bottom {
    height: 700px;
    background-color: #fff;
    margin-bottom: 16px;
    overflow: scroll;
    .bottom-title {
        // width: 1650px;
        margin-right: 24px;
        height: 40px;
        border-bottom: 1px solid #E4E7ED;
        margin-left: 24px;
        margin-bottom: 18px;

        .title-text {
            display: inline-block;
            font-family: PingFang SC, PingFang SC;
            width: 56px;
            height: 40px;
            line-height: 40px;
            color: #303133;
            font-size: 14px;
            font-weight: 500;
            margin-left: 20px;
            cursor: pointer;
        }

        .active-text {
            display: inline-block;
            font-family: PingFang SC, PingFang SC;
            width: 56px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            margin-left: 20px;
            cursor: pointer;
            color: #409EFF !important;
            border-bottom: 1px solid #409EFF !important;
            font-weight: 600 !important;
        }

    }

    .bottom-content {
        margin-left: 24px;

        .time {
            display: flex;
            align-items: center;

            img {
                width: 18px;
                height: 18px;
                margin-top: 8px;
            }

            .time-text {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #303133;
                font-size: 16px;
                margin-left: 14px;
                margin-top: 8px;
            }
        }

        .serve {
            margin-right: 51px;
            margin-left: 9px;
            margin-top: 8px;
            // background-color: pink;
            padding-left: 23px;
            border-left: 1px dashed #DCDFE5;

            .serve-person {
                margin-top: 5px;
                font-family: PingFang SC, PingFang SC;
                font-size: 14px;
                color: #606266;
            }

            .box-width {
                padding-bottom: 20px;

                .serve-content {
                    background-color: #F8F8F8;
                    margin-top: 16px;
                    display: flex;

                    .SC-text {
                        padding: 20px 51px 20px 20px;
                        width: 436px;

                        .SC-title {
                            font-family: Noto Sans SC, Noto Sans SC;
                            font-weight: 300;
                            font-size: 12px;
                            color: #2F3941;
                        }

                        .SC-content {
                            margin-top: 4px;
                            font-family: Noto Sans SC, Noto Sans SC;
                            font-weight: 500;
                            font-size: 14px;
                            color: #2F3941;
                        }
                    }
                }
            }


        }
    }
}
</style>